import './Experience.css'
import Workbox from './Workbox';
import UCA from './Media/UCA.jpg';
import EK from './Media/ekanyone.jfif';
import ZAS from './Media/zas.jfif';
import SYEP from './Media/syep.png';
import BINGY from './Media/bingy.png';

const Experience = () => {
    return(
        <div className="experience">
            <h1 className="experience__title">
                Work Experience
            </h1>
            <Workbox 
                title="Graduate Assistant @ Binghamton University"
                pic={BINGY}
                description="Currently I am a graduate assistant and conduct
                machine learning research under the wing of Dr. Jian Li. We are 
                working with Watchroom, an app that allows users to chat about
                recent anime episodes with other users. I am using Deep Learning
                to develop a Voice Conversion model, so that users can apply 
                filters to their voice, if they'd like anonymity."
                link="https://www.binghamton.edu/electrical-computer-engineering/research/index.html"
                view="Company"
            />
            <Workbox 
                title="Course Assistant @ Binghamton University"
                pic={UCA}
                description="During my final semester as an undergrad, I was a course assistant 
                for a Microcontroller Design Lab course at Binghamton University. This 
                course used C programming to program hardware like microcontrollers. 
                I helped students with questions that they had in regards to lab 
                assignments and projects where they had to program the soft 
                microprocessor MicroBlaze. I was also available for electronic 
                assistance via email and helped the students in the conceptual 
                development of their final projects."
                link="https://www.binghamton.edu/electrical-computer-engineering/undergraduate/computer-engineering.html"
                view="Company"
            />
            <Workbox 
                title="Software Engineering Intern @ eKanyone"
                pic={EK}
                description="During my Summer '21 internship at eKanyone, I got 
                to work on a team of interns where I vastly improved my skills and 
                knowledge on both front and backend programming. I got to contribute 
                to the design and development of a full stack CRUD ecommerce website 
                using ReactJS and Django. We redesigned the front end of the company
                website and connected it to a mock backend."
            />
            <Workbox 
                title="Technology/Web Dev Intern @ Zen Art Soul"
                pic={ZAS}
                description="During my internship at Zen Art Soul I greatly improved 
                my HTML,CSS, and JavaScript skills, as I was responsible for keeping 
                the store and company websites updated and fresh. The company used 
                Weebly, but I constantly found myself editing HTML/CSS code to change 
                templates and designs. I also worked with the photography intern 
                to keep a fresh and changing gallery of art displayed on the website."
                link="http://zenartssoul.com/index.html"
                view="Company"
            />
            <Workbox 
                title="Digital Art Intern @ SYEP"
                pic={SYEP}
                description="During my internship at SYEP @ Laguardia Community College, 
                I learned problem solving skills and polished my creativity with 
                various projects throughout the summer. For my final project, I created 
                a website that compiled the final projects of all my other fellow 
                interns. I published the website to GitHub Pages and my supervisor 
                used my final project to present to the administration."
                link="https://www1.nyc.gov/site/dycd/services/jobs-internships/summer-youth-employment-program-syep.page"
                view="Company"
            />
        </div>
    );
}

export default Experience